/* Miscellanoeous */

body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto !important;
}

.scroll-container {
  position: relative;

  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }

  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}